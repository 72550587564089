<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <template v-if="props.item.show">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">
              <v-btn
                :disabled="props.item.childOf != null"
                @click="handleVisibility(props.item.id)"
                >Toggle</v-btn
              >
            </td>
          </template>
          <template v-else>
            <tr>
              I'm invisible
            </tr>
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar class="accent" flat>
            <v-toolbar-title><h3>Registered Users</h3></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="ml-4 mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New User
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">New User</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-autocomplete
                        v-model="editedItem.customer"
                        :rules="customerRules"
                        auto-select-first
                        :items="customers"
                        item-text="clastname"
                        item-value="custledgkey"
                        label="User Name"
                        required
                        dense
                        outlined
                        @change="getUserRecord"
                      ></v-autocomplete>

                      <v-text-field
                        label="Email"
                        type="email"
                        v-model="editedItem.email"
                        :rules="emailRules"
                        required
                        dense
                        outlined
                      ></v-text-field>

                      <v-text-field
                        label="Password"
                        :type="showpwd ? 'text' : 'password'"
                        v-model="editedItem.password"
                        :rules="passwordRules"
                        required
                        dense
                        outlined
                        :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showpwd = !showpwd"
                      ></v-text-field>
                      <v-autocomplete
                        v-model="editedItem.restriction"
                        auto-select-first
                        :items="restrictions"
                        :rules="restrictionRules"
                        item-text="option"
                        item-value="value"
                        label="Data Restriction Period"
                        required
                        dense
                        outlined
                        @change="getRestriction"
                      ></v-autocomplete>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary white--text"
                    :disabled="!valid"
                    text
                    @click="saveUser"
                  >
                    Register
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card class="pt-4 pb-4">
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this user?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary white--text" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary white--text"
                    text
                    @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSettings" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Change User Settings</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        label="User Name"
                        type="email"
                        v-model="editedItem.name"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                      <v-text-field
                        label="Email"
                        type="email"
                        v-model="editedItem.email"
                        dense
                        outlined
                        readonly
                      ></v-text-field>
                      <v-autocomplete
                        v-model="editedItem.restriction"
                        :items="restrictions"
                        :rules="restrictionRules"
                        item-text="option"
                        item-value="value"
                        label="Data Restriction Period"
                        persistent-hint
                        return-object
                        single-line
                        dense
                        outlined
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.option }}
                        </template>
                      </v-autocomplete>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-radio-group v-model="editedItem.role">
                            <template v-slot:label>
                              <div><strong>USER ROLE</strong></div>
                            </template>
                            <v-radio value="ADMIN">
                              <template v-slot:label>
                                <div>Administrator</div>
                              </template>
                            </v-radio>
                            <v-radio value="CLIENT">
                              <template v-slot:label>
                                <div>Client</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-radio-group v-model="editedItem.verified">
                            <template v-slot:label>
                              <div><strong>EMAIL VERIFIED?</strong></div>
                            </template>
                            <v-radio value="YES">
                              <template v-slot:label>
                                <div>Yes</div>
                              </template>
                            </v-radio>
                            <v-radio value="NO">
                              <template v-slot:label>
                                <div>No</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-radio-group v-model="editedItem.active">
                            <template v-slot:label>
                              <div><strong>IS ACTIVE?</strong></div>
                            </template>
                            <v-radio value="YES">
                              <template v-slot:label>
                                <div>Yes</div>
                              </template>
                            </v-radio>
                            <v-radio value="NO">
                              <template v-slot:label>
                                <div>No</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary white--text"
                    text
                    @click="closeSettings"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary white--text"
                    :disabled="!valid"
                    text
                    @click="updateSettings"
                  >
                    Save Settings
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editItem(item)"> mdi-cog </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getUsers"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import api from "../../services/axiosconfig";
import { db } from "../../services/firebase";
import { doc, getDoc } from "firebase/firestore";

export default {
  data: () => ({
    dialog: false,
    dialogSettings: false,
    valid: true,
    dialogDelete: false,
    search: "",
    loading: false,
    showpwd: false,
    totp_secret: "",
    headers: [
      { text: "User Name", align: "start", value: "name" },
      { text: "Registered Email", align: "start", value: "email" },
      { text: "Created", align: "start", value: "created" },
      { text: "Signed In", align: "start", value: "loggedin" },
      { text: "Role", align: "start", value: "role" },
      { text: "Verified", align: "start", value: "verified" },
      { text: "Active", align: "start", value: "active" },
      { text: "Data Limit", align: "start", value: "restriction.option" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
        width: "10%",
        fixed: true,
      },
    ],
    restrictions: [
      { option: "3 Months", value: 3 },
      { option: "6 Months", value: 6 },
      { option: "9 Months", value: 9 },
      { option: "12 Months", value: 12 },
      { option: "15 Months", value: 15 },
      { option: "18 Months", value: 18 },
      { option: "21 Months", value: 21 },
      { option: "24 Months", value: 24 },
      { option: "36 Months", value: 36 },      
      { option: "48 Months", value: 48 },      
      { option: "60 Months", value: 60 },      
    ],
    users: [],
    customers: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      customer: {},
      email: "",
      password: "",
      restriction: {
        option: "3 Months",
        value: 3,
      },
    },
    defaultItem: {
      id: "",
      customer: {},
      email: "",
      password: "",
      restriction: {
        option: "3 Months",
        value: 3,
      },
    },
    customerRules: [(v) => !!v || "User is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 6 || "Password must at least 6 characters",
    ],
    restrictionRules: [(v) => !!v || "Data Limit Period is required"],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogSettings(val) {
      val || this.closeSettings();
    },
  },

  mounted() {
    this.getUsers();
    this.getCustomers();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  methods: {
    getUserRecord() {
      const filteredCustomer = this.customers.filter(
        (cust) => cust.custledgkey === this.editedItem.customer
      );
      this.editedItem.customer = filteredCustomer[0];
    },

    getRestriction() {
      const filteredRestriction = this.restrictions.filter(
        (permit) => permit.value === this.editedItem.restriction
      );
      this.editedItem.restriction = filteredRestriction[0];
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogSettings = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        await api.delete(`/aejmis/api/users/deleteUser/${this.editedItem.id}`);
        this.users.splice(this.editedIndex, 1);
        this.$toasted.global.optimus_info({
          message: "User account deleted successfully.",
        });
      } catch (error) {
        this.$toasted.global.optimus_error({
          message: "Could not delete the user. Something went wrong.",
        });
      }

      this.closeDelete();
    },

    closeSettings() {
      this.dialogSettings = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.customer = {
          clastname: this.customers[0].clastname,
          custledgkey: this.customers[0].custledgkey,
        };
        this.editedIndex = -1;
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.customer = {
          clastname: this.customers[0].clastname,
          custledgkey: this.customers[0].custledgkey,
        };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.customer = {
          clastname: this.customers[0].clastname,
          custledgkey: this.customers[0].custledgkey,
        };
        this.editedIndex = -1;
      });
    },

    async saveUser() {
      if (this.$refs.form.validate()) {
        console.log(this.editedItem);
        this.loading = true;
        api
          .post("/aejmis/api/users/signup", {
            email: this.editedItem.email,
            password: this.editedItem.password,
            photoURL: "img/user.png",
            customer: this.editedItem.customer,
            restriction: this.editedItem.restriction,
          })
          .then(() => {
            const mailOptions = {
              name: this.editedItem.customer.clastname,
              email: this.editedItem.email,
              message: this.editedItem.password,
            };
            api
              .post("/sendClientMail", mailOptions)
              .then(() => {
                this.$toasted.global.optimus_info({
                  message:
                    "The login credentials have been sent to the client's registered email address.",
                });
                this.getUsers();
                this.loading = false;
                this.dialog = false;
              })
              .catch(() => {
                this.$toasted.global.optimus_error({
                  message:
                    "Error sending login credentials to the client's registered email address.",
                });
              });
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    async updateSettings() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await api.put(`/aejmis/api/users/updateUser/${this.editedItem.id}`, {
            role: this.editedItem.role,
            verified: this.editedItem.verified,
            active: this.editedItem.active,
            restriction: this.editedItem.restriction,
          });
          this.getUsers();
          this.loading = false;
          this.dialogSettings = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },

    async getUsers() {
      let role;
      let active;
      let verified;
      let restrict;

      this.users = [];
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/users");
        const data = response.data;
        data.map(
          ({
            uid,
            displayName,
            email,
            emailVerified,
            disabled,
            metadata,
            customClaims,
          }) => {
            getDoc(doc(db, "users", uid))
              .then((snapshot) => {
                if (customClaims.admin) {
                  role = "ADMIN";
                } else {
                  role = "CLIENT";
                }
                if (disabled) {
                  active = "NO";
                } else {
                  active = "YES";
                }
                if (emailVerified) {
                  verified = "YES";
                } else {
                  verified = "NO";
                }
                if (snapshot.exists()) {
                  restrict = snapshot.data().restriction;
                } else {
                  restrict = {};
                }
                this.users.push({
                  id: uid,
                  name: displayName,
                  email: email,
                  verified: verified,
                  created: metadata.creationTime,
                  loggedin: metadata.lastSignInTime,
                  active: active,
                  role: role,
                  restriction: restrict,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve information. Try after sometime.",
        });
      }
    },

    async getCustomers() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/customers");
        this.customers = response.data;
        this.loading = false;
        this.editedItem.customer = {
          clastname: this.customers[0].clastname,
          custledgkey: this.customers[0].custledgkey,
          custledgref: this.customers[0].custledgref,
          caddress1: this.customers[0].caddress1,
          caddress2: this.customers[0].caddress2,
          caddress3: this.customers[0].caddress3,
          cpostcode: this.customers[0].cpostcode,
          ctelnumber: this.customers[0].ctelnumber,
          ccontactname: this.customers[0].ccontactname,
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  color: rgb(255, 255, 255) !important;
  background-color: #0b2545 !important;
}
.theme--light.v-btn--disabled {
  color: rgb(255, 255, 255) !important;
  background-color: #b8b8b8 !important;
}
</style>
